import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import Front from "./components/Front";
import BecomeAHero from "./components/BecomeAHero";
import NeedAHero from "./components/NeedAHero";
import PrivacyPolicy from "./components/PrivacyPolicy";
import OurStory from "./components/OurStory";
import SPTermsAndConditions from "./components/SPTermsAndConditions";
import TermsAndConditions from "./components/TermsAndConditions";

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'home',
        component: Front
    },
    {
        path: '/become-a-hero',
        component: BecomeAHero,
        name: 'becomeAHero'
    },
    {
        path: '/need-a-hero',
        component: NeedAHero,
        name: 'needAHero'
    },
    {
        path: '/our-story',
        component: OurStory,
        name: 'ourStory'
    },
    {
        path: '/terms-and-conditions/sp',
        component: SPTermsAndConditions,
        name: 'spTermsAndConditions'
    },

    {
        path: '/terms-and-conditions/',
        component: TermsAndConditions,
        name: 'termsAndConditions'
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicy,
        name: 'privacyPolicy'
    }
];

const router = new VueRouter({
    routes,
    scrollBehavior(to, from, savedPosition) {
        return {x: 0, y: 0}
    }
});


router.beforeEach((to, from, next) => {

    // if logged in redirect to dashboard
    if (to.path === '/login' && store.state.isLoggedIn) {
        next({name: 'dashboard'})
        return
    }

    next()
});


export default router
