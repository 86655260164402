<template>
    <main class="main" role="main">

        <div class="grid no-gut">
            <div class="grid-xs-12 grid-s-6 hero-section with-bg" :style="{ backgroundImage: `url(${require('@/assets/handshake.jpg')})` }">
                <div class="hero-content">
                    <div class="text-center">
                        <i class="icon lni-briefcase"></i>
                        <p class="intro-text"><strong>Become</strong> a</p>
                        <p class="big-text">Hero</p>

                        <router-link :to="{name: 'becomeAHero'}" class="button">Become a hero</router-link>
                    </div>
                </div>
            </div>

            <div class="grid-xs-12 grid-s-6 hero-section with-bg" :style="{ backgroundImage: `url(${require('@/assets/3980.jpg')})` }">
                <div class="hero-content">
                    <div class="text-center">
                        <i class="icon lni-search"></i>
                        <p class="intro-text"><strong>Need</strong> a</p>
                        <p class="big-text">Hero</p>
                        <router-link :to="{name: 'needAHero'}" class="button">Need a hero</router-link>
                    </div>
                </div>
            </div>
        </div>

        <section class="section call-out-image">
            <div class="centering">
                <div class="grid">
                    <div data-aos="fade-down" class="grid-xs-12 grid-s-6">
                        <div class="callout-meta">

                            <h2 class="h1">Our Story.</h2>
                            <p> Here at Heroes on Tap our aim is to link up services and clients in a seamless, timely fashion. After discovering how difficult it
                            can be to contact a service provider, we decided to create an app to put control into the palm of your hand.</p>

                            <p>So whether you're in need of a hero to complete a problem or you're a hero in waiting, look to spring into action, we have what you need.</p>
                        </div>
                    </div>

                    <div data-aos="fade-up" class="grid-xs-12 grid-s-6">
                        <img class="callout-image" src="../assets/map.svg" alt="White Iphone">
                    </div>
                </div>
            </div>
        </section>


<!--        <section class="section call-out-image grey-bg">-->
<!--            <div class="centering">-->
<!--                <div class="grid">-->
<!--                    <div data-aos="fade-down" class="grid-xs-12 grid-s-6">-->
<!--                        <div class="callout-meta">-->
<!--                            <h2 class="h1">Talk to us.</h2>-->
<!--                            <p>-->
<!--                                Become a hero today and be seen by anybody in need of help within your area. Build your-->
<!--                                business from the palm of your hand and enhance your reputation with potential clients-->
<!--                                near-->
<!--                                you.-->
<!--                            </p>-->
<!--                        </div>-->

<!--                    </div>-->

<!--                    <div data-aos="fade-up" class="grid-xs-12 grid-s-6">-->
<!--                        <div v-if="contactResponse">{{ contactResponse }}</div>-->
<!--                        <form class="contact-form" v-on:submit.prevent="sendContact">-->
<!--                            <div class="input-wrapper">-->
<!--                                <label>Your Name</label>-->
<!--                                <input type="text" v-model="name">-->
<!--                            </div>-->

<!--                            <div class="input-wrapper">-->
<!--                                <label>Email Address</label>-->
<!--                                <input type="text" v-model="email">-->
<!--                            </div>-->

<!--                            <div class="input-wrapper">-->
<!--                                <label>Message</label>-->
<!--                                <textarea rows="5" v-model="message"></textarea>-->
<!--                            </div>-->

<!--                            <div class="input-wrapper">-->
<!--                                <button class="button">Send</button>-->
<!--                            </div>-->
<!--                        </form>-->
<!--                    </div>-->
<!--                </div>-->
<!--            </div>-->
<!--        </section>-->
    </main>
</template>

<script>
    import Signup from "./Signup";

    export default {
        components: {Signup},
        data() {
            return {
                contactResponse: '',
                name: '',
                email: '',
                message: ''
            }
        },

        methods: {
            sendContact() {
                axios.post('/send-contact', this.$data).then(response => {
                    this.contactResponse = response.data;
                    this.name = '';
                    this.email = '';
                    this.message = '';
                })
            }
        }
    }
</script>

<style scoped>

</style>
