<template>
    <div>
        <div class="grid">
            <div class="grid-xs-12 hero-section with-bg" :style="{ backgroundImage: `url(${require('@/assets/3980.jpg')})` }">
                <div class="hero-content">
                    <div class="text-center">
                        <i class="icon lni-briefcase"></i>
                        <p class="intro-text"><strong>Find</strong> a</p>
                        <p class="big-text">Hero</p>
                    </div>
                </div>
            </div>
        </div>

        <signup></signup>

        <section class="section call-out-image">
            <div class="centering">
                <div class="grid">
                    <div data-aos="fade-down" class="grid-xs-12 grid-s-6">
                        <div class="callout-meta">
                            <h2 class="h1">Find trades within seconds. </h2>
                            <p>
                                Sign up today to make finding the hero that you need a quick and simple task. Simply tap the app, search for the help
                                that you want and the app will do the rest.
                            </p>
                        </div>
                    </div>

                    <div data-aos="fade-up" class="grid-xs-12 grid-s-6">
                        <img class="callout-image" src="../assets/map.svg" alt="White Iphone">
                    </div>
                </div>
            </div>
        </section>

        <section class="section grey-bg">
            <div class="centering">
                <h2 class="h1">Our Features.</h2>
                <div class="grid">
                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/realtime.svg" alt="realtime">
                            <h2 class="icon-title">What you need, when you need it</h2>
                            <p>Real time GPS locations and availability indicators to show the right hero available in your area. </p>
                        </div>
                    </div>

                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/phone.svg" alt="realtime">
                            <h2 class="icon-title">Get the hero that you need from your phone</h2>
                            <p>No more searching through contact pages to try and find a hero, just tap the app and go. </p>
                        </div>
                    </div>

                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/map.svg" alt="realtime">
                            <h2 class="icon-title">On call services</h2>
                            <p>See heroes that are available now in your area. </p>
                        </div>
                    </div>

                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/callback.svg" alt="realtime">
                            <h2 class="icon-title">Call back requests</h2>
                            <p>DonNot urgent? Just hit the call back to have a hero contact you at their soonest availability.</p>
                        </div>
                    </div>

                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/im.svg" alt="realtime">
                            <h2 class="icon-title">Instant messaging</h2>
                            <p>Start a connection with a hero by dropping them a message in our instant messaging system.</p>
                        </div>
                    </div>

                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/card.svg" alt="realtime">
                            <h2 class="icon-title">Free membership</h2>
                            <p>All of our heroes are completely independent, so all of your payments go directly to them!</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>


    </div>
</template>

<script>
    import Signup from "./Signup";
    export default {
        name: "NeedAHero",
        components: {Signup},

    }
</script>

<style scoped>

</style>
