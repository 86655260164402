<template>
    <div class="footer">
        <div class="centering">
            <div class="grid">
                <div class="grid-xs-12 grid-s-4">
                    <h3>H.O.T.</h3>
                    <p>What you need, when you need it.</p>
                </div>
                <div class="grid-xs-12 grid-s-4">
                    <h3>Pages.</h3>
                    <ul class="footer-list">
                        <li><router-link :to="{name: 'spTermsAndConditions'}">Terms and Conditions (Service Providers)</router-link> </li>
                        <li><router-link :to="{name: 'termsAndConditions'}">Terms and Conditions (Users)</router-link> </li>
                        <li><router-link :to="{name: 'privacyPolicy'}">Privacy Policy</router-link> </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer"
    }
</script>

<style scoped>

</style>
