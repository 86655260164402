<template>
    <div class="centering">

        <div class="card legal">
            <h1>Customer Terms and Conditions</h1>
            <p>Heroes on Tap Limited, Registered as a company in England and Wales at 123 Cross Lane, Gravesend, Kent DA12 5HA with reference number : 2015776</p>

            <ol>
                <li>Acceptance terms
                    <ol>
                        <li>Your access to and use of Heroes on Tap Limited ("the Website and/or App") and the Services (defined below) are subject exclusively to these Terms and Conditions. References to Website/App mean Website and/or App.</li>
                        <li>You agree not to use the Website and/or App and/or Services for any purpose that is unlawful or prohibited by these Terms and Conditions.</li>
                        <li>By using the Website and/or App and/or Services you are fully accepting the Terms and Conditions. If you do not accept these Terms and Conditions you must immediately stop using the Website and/or App and/or Services.</li>
                        <li>Heroes on Tap Limited reserves the right to update or amend these Terms and Conditions at any time and your continued use of the Website and/or App and/or Services following any changes shall be deemed to be your acceptance of such change. It is therefore your responsibility to check the Terms and Conditions regularly for any changes.</li>
                        <li>Heroes on Tap Limited may update the Website and or App from time to time and may change the content at any time.</li>
                        <li>These Terms and conditions were last updated on 28th August 2019.</li>
                    </ol>
                </li>

                <li>The services
                    <ol>
                        <li>Heroes on Tap Limited introduces Customers to Service Providers who may offer services direct to Customers.</li>
                        <li>Customers contract directly with Service Providers.  Price and services are agreed direct with them and Customers understand and agree that Heroes on Tap Limited does not contract with customers for any services provided by Service Providers.</li>
                        <li>Heroes on Tap Limited takes steps to verify the credentials and company status of its Service Providers (the “Vetting Process”).</li>
                        <li>Heroes on Tap Limited collects and collates feedback from customers of its Service Providers by means of feedback (“Monitoring”).</li>
                        <li>Heroes on Tap Limited collates recommendations from its customers in relation to its Service Providers and the services they provide (“Recommendations”).</li>
                        <li>The results of the Recommendation, Vetting and Monitoring processes are published on the Website/App.</li>
                        <li>The availability and location of the Service Providers are displayed on the Website/App</li>
                    </ol>

                    <br>

                    <div>
                        Heroes on Tap Limited aims to:<br>
                        Provide a service to customers to allow connection to services most suited to their need in real time.<br>
                        Improve the accountability and credibility of its Service Providers through publishing impartial feedback on their work;<br>
                        Promote quality service, workmanship and best practice among its Service Providers; and<br>
                        Promote its Service Providers to the public, isolating rogue traders.
                    </div>
                </li>

                <li>Other Applicable Terms
                    <ol>
                        <li>These Terms and Conditions refer to the following additional terms, which also apply to your use of the Website/App:</li>
                        <li>Heroes on Tap Ltd privacy notice which sets out the terms on which we process any personal data we collect from you or that you provide to Heroes on Tap Limited(“Privacy Notice”). The Privacy Notice also sets out who we share the content you upload or provide with;</li>
                    </ol>
                </li>

                <li>Access to website
                    <ol>
                        <li>Heroes on Tap Limited do not guarantee that the App/Website or any content contained on the App/Website will always be available or be uninterrupted.</li>
                        <li>You are responsible for making all arrangements necessary for you to have access to the Website/App.</li>
                        <li>Your access to the Website/App is permitted on a temporary basis only.</li>
                        <li>Heroes on Tap Limited may suspend, withdraw, discontinue or change all or any part of the Website/App without notice to you.</li>
                        <li>Heroes on Tap Limited will not be liable to you for any reason if the Website/App is unavailable at any time or for any period.</li>
                    </ol>
                </li>

                <li>Intellectual Property Rights
                    <ol>
                        <li>The Website/App and their content (including without limitation the design, text, graphics and all software and source codes connected with the Website/App and the Services) are protected by copyright, trademarks, and other intellectual property rights and laws. In accessing the Website/App you agree that you will access the contents solely for your personal, non-commercial use.</li>
                        <li>None of the content contained on the Website may be downloaded, copied, reproduced, transmitted, stored, sold or distributed without the prior written consent of Heroes on Tap Ltd. This excludes the downloading, copying and/or printing of pages of the Website/App for personal, non-commercial use only.</li>
                        <li>When you upload any content (including text or photographs) to the Website/App, you assign all rights in such content to Heroes on Tap Limited.</li>
                        <li>You agree to Heroes on Tap Limited using the name you submit in connection with the content you upload.</li>
                    </ol>
                </li>

                <li>No reliance on information or speed of service
                    <ol>
                        <li>The content contained on the Website/App is provided for general information only. It is not intended to amount to advice on which you should rely.</li>
                        <li>Although Heroes on Tap Limited makes reasonable efforts to update the information on the Website/App, please note that the content contained on the Website/App may be out of date at any given time and Heroes on Tap Limited are under no obligation to update it.</li>
                        <li>In case of customers experiencing emergency situations Heroes on Tap Limited aims to put such customers in touch with Service Providers who are able to provide a fast response, however, Heroes on Tap Limited cannot guarantee a specific response time and will not be liable for the failure of any Service Provider to succeed in providing any service in any specific time quoted.</li>
                    </ol>
                </li>

                <li>Prohibited use

                    <ol>
                        <li>You agree to use the Website/Appfor lawful purposes only and not:

                        <ol>
                            <li>In any way that breaches any applicable local, national or international law or regulation;</li>
                            <li>In a way that is unlawful or fraudulent, or has any unlawful or fraudulent purpose or effect;</li>
                            <li>For the purposes of harming or attempting to harm any person, business or entity in anyway;</li>
                            <li>To undermine the purpose and provision of Services;</li>
                            <li>To gain a competitive advantage over any businesses in your sector;</li>
                            <li>To act in competition with the Services;</li>
                            <li>To violate the privacy of others;</li>
                            <li>To transmit, or procure the sending of, any unsolicited or unauthorised advertising or promotional material or any other form of solicitation;</li>
                            <li>To reverse engineer, decompile, dissemble, decipher or otherwise derive the code used on the Website;</li>
                            <li>To use any robot, spider, scraper, or other automated means to access the Website; or</li>
                            <li>To reproduce, duplicate, copy, sub-licence, broadcast, distribute, sell, re-brand or re-sell any part of the Website (without Heroes on Tap Limited’s express consent).</li>
                        </ol>
                        </li>
                    </ol>
                </li>

                <li>Links to third party websites and linking to the Website

                    <ol>
                        <li>The Website/App and Services may include links to third party websites that are controlled and maintained by others. Any link to other websites is not an endorsement of such websites and you acknowledge and agree that Heroes on Tap Limited has no control over such websites and is not responsible for the content or availability of any such website.</li>
                    </ol>
                </li>


                <li>Viruses
                    <ol>
                        <li>Heroes on Tap Limited cannot guarantee that the Website/App will be secure or free from bugs or viruses.</li>
                        <li>Heroes on Tap Limited will not be liable for any loss or damage caused by a virus, or any other technologically harmful material that may infect your computer equipment, data, computer programs or other proprietary material due to your use of the Website/App or to your downloading of any content on the Website/App, where Heroes on Tap Limited have taken reasonable security to prevent this.</li>
                    </ol>
                </li>


                <li>Indemnity
                    <ol>
                        <li>You agree to indemnify and hold Heroes on Tap Limited and its employees and agents harmless from and against any breach by you of these Terms and Conditions and any claim or demand brought against Heroes on Tap Limited by any third party arising out of your use of the Services and/or any Content submitted, posted or transmitted through the Services, including without limitation, all claims, actions, proceedings, losses, liabilities, damages, costs, expenses (including reasonable legal costs and expenses) howsoever suffered or incurred by Heroes on Tap Limited in consequence of your breach of these Terms and Conditions.</li>
                    </ol>
                </li>

                <li>Disclaimers and limitation of liability

                <ol>
                    <li>Use of the Website/App and Services is at your own risk. The Website/App and Services are provided on an "AS IS" and "AS AVAILABLE" basis without any representation or endorsement made and without warranty of any kind whether express or implied, including but not limited to the implied warranties of satisfactory quality, fitness for a particular purpose, non-infringement, compatibility, security and accuracy.</li>
                    <li>To the extent permitted by law, Heroes on Tap Limited will not be liable for any indirect or consequential loss or damage whatsoever (including without limitation loss of business, opportunity, data, profits) arising out of or in connection with the use of the Website/App and Services.</li>
                    <li>Whilst Heroes on Tap Limited makes reasonable efforts to ensure that all information it provides is accurate, Heroes on Tap Limited makes no warranty that the Website/App and Services will meet your requirements, that the Content will be accurate or reliable, that the functionality of the Website/App and Services will be uninterrupted or error free, that defects will be corrected or that the Website/App and Services or the server that makes them available are free of viruses or anything else that may be harmful or destructive.</li>
                    <li>Heroes on Tap Limited will not be liable to you for any loss suffered (even if such loss is foreseeable) in relation to your use or inability to use the Website/App, any inaccuracies or delays on the Website/App or any reliance by you on content contained on the Website.</li>
                    <li>Nothing in these Terms and Conditions shall be construed so as to exclude or limit the liability of Heroes on Tap Limited for death or personal injury as a result of the negligence of Heroes on Tap Limited or that of its employees or agents.</li>
                    <li>Nothing in these Terms and Conditions shall affect your statutory rights as a consumer.</li>
                    <li>Whilst every effort is made to verify statements and information submitted by customers and Heroes on Tap Limited Service Providers, the information compiled by Heroes on Tap Limited is based on information supplied by various individuals and businesses, it does not represent the views of Heroes on Tap Limited and Heroes on Tap Limited cannot be held responsible for any omissions or inaccurate claims that may appear.</li>
                    <li>Users of the service do hereby represent, understand and expressly agree that Heroes on Tap Limited does not make any warranty or guarantee over the quality, timing, integrity, responsibility, legality or any other aspect whatsoever of the materials or services offered or delivered by its Service Providers to their customers.</li>
                    <li>You hereby expressly agree not to hold Heroes on Tap Limited(or Heroe on Tap Limited’s  agents, representatives, advertisers or employees) liable for any instruction, advice or services delivered which originated through the website/App or associated Services and Heroes on Tap Limited expressly disclaims any liability whatsoever for any damage, suits, claims, and/or controversies that have arisen or may arise, whether known or unknown therefrom.</li>
                </ol>
                </li>

                <li>Uploading content to the Website

                <ol>
                    <li>If you are over the age of 18 you are permitted to upload content. Where you upload content to the Website/App you must ensure that all content uploaded:</li>
                    <li>Is accurate, true, up to date, genuine and not misleading;</li>
                    <li>Is relevant and appropriate;</li>
                    <li>Complies with applicable law in the UK and in any country from which the content is posted or viewed and does not promote illegal activity;</li>
                    <li>Complies with the Privacy Notice and does not disclose any personal or confidential information;</li>
                    <li>Does not contain material which is or potentially could be defamatory, obscene, aggressive, offensive, hateful, inflammatory or rude;</li>
                    <li>Does not infringe the copyright or any intellectual property rights of any person and where such content relates to an image, you must ensure that all necessary consents and authorisations have been obtained;</li>
                    <li>Does not promote discrimination based on race, sex, religion, nationality, disability, sexual orientation or age;</li>
                    <li>Does not relate to the services provided by Hereoes on Tap Limited;</li>
                    <li>Does not have the purpose or effect of advertising, discussing or commenting on third party businesses who are not a Member;</li>
                    <li>Is not threatening, abusive or invades others privacy or causes annoyance, inconvenience or needless anxiety;</li>
                    <li>Does not impersonate any person or misrepresent identities or affiliations; or</li>
                    <li>Does not give the impression that it emanates from Heroes on Tap Limited (if this is not the case).</li>
                    <li>Content Standards:
                        <ol>
                            <li>Where content you upload does not comply with the Content Standards or requires clarification, Heroes on Tap Limited may contact you for further information, before publishing such content.</li>
                            <li>You warrant that all content you upload shall comply with the Content Standards, and you agree to indemnify Heroes on Tap Limited for any loss Heroes on Tap Limited suffers as a result of your failure to comply with the Content Standards.</li>
                            <li>Heroes on Tap Limited will not be responsible, or liable to any third party, for the content or accuracy of any content uploaded by you to the Website.</li>
                            <li>Heroes on Tap Limited reserves the right to remove or withhold any content at any time which it considers fails to adhere with the Content Standards.</li>
                            <li>Heroes on Tap Limited reserves the right to edit content you upload to amend spelling, grammar, punctuation, length, for the purposes of clarity and to ensure that the content can comply with the Content Standards.</li>
                        </ol>
                    </li>
                </ol>
                </li>

                <li>Heroes on Tap Limited
                    <ol>
                        <li>Heroes on Tap Limited will determine at its discretion, whether you have failed to comply with these Terms and Conditions. Failure to comply with these Terms and Conditions constitutes a material breach and may result in Heroes on Tap Limited:

                            <ol>
                                <li>Temporarily or permanently withdraw your access to the Website/App;</li>
                                <li>Commence legal proceedings against you for your actions;</li>
                                <li>Disclose necessary information to law enforcement authorities.</li>
                            </ol>
                        </li>
                    </ol>
                </li>

                <li>Severance
                    <ol>
                        <li>If any of these Terms and Conditions should be determined to be invalid, illegal or unenforceable for any reason by any court of competent jurisdiction then such Term or Condition shall be severed and the remaining Terms and Conditions shall survive and remain in full force and effect and continue to be binding and enforceable.</li>
                    </ol>
                </li>

                <li>Governing Law
                    <ol>
                        <li>These Terms and Conditions shall be governed by and construed in accordance with the law of England and Wales and you hereby submit to the exclusive jurisdiction to the courts of England and Wales.</li>
                    </ol>
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
    export default {
        name: "TermsAndConditions"
    }
</script>

<style scoped>

</style>
