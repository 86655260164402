<template>
    <div class="centering">
        <div class="card legal">
            <h1 class="h1">Privacy Policy</h1>
            <p>
                We are Heroes on tap Limited (“we”, “our”, “us”) and operate under the name of Heroes on tap.
                We’re committed to protecting and respecting your privacy. If you have any questions about your personal
                information please chat with us through the Heroes on Tap Limited app, or by emailing us at
                <a href="mailto:help@heroesontap.co.uk">help@Heroesontap.co.uk</a>, or by writing to us at Heroes on Tap
                Limited at 123 Cross Lane, Gravesend, Kent, DA12 5HA.
            </p>

            <h2>The information we hold about you.</h2>

            <ul>
                <li>
                    <strong>Information Submitted through our app or website</strong>
                    <ul>
                        <li>For example, when you sign up to the app and provide details such as your name, ID, selfie
                            and address, or details we collect about how you use the app and website.
                            <br>
                            All transaction data, including any Service Provider to customer and customer to Service
                            Provider interaction.
                        </li>
                    </ul>
                </li>

                <li>
                    <strong>Details about your transactions with us</strong>
                    <ul>
                        <li>Including accounts you use</li>
                    </ul>
                </li>

                <li>
                    <strong>Information on how you use your phone</strong>
                    <ul>
                        <li>For example the mobile network you use, your IP address or operating system and the settings
                            on your phone.
                        </li>
                    </ul>
                </li>

                <li>
                    <strong>Information from social networks or online accounts</strong>
                    <ul>
                        <li>Information from any accounts that you share with us.</li>
                    </ul>
                </li>

                <li>
                    <strong>Cookie information</strong>
                    <ul>
                        <li>Read our
                            <router-link :to="{name: 'cookies'}">Cookie Policy</router-link>
                            for more info on what cookies are and how we use them.
                        </li>
                    </ul>
                </li>
            </ul>

            <h2>How we use your information</h2>
            <p>To provide services, we use it to:</p>
            <ul>
                <li>Help us maintain the account</li>
                <li>Track, analyse and improve the services we give you and other customers</li>
                <li>To record information requested regarding serivce providers by customers</li>
                <li>To create review information to provide a more accurate service for customers</li>
            </ul>

            <p>To meet our legal oblications, we use it to:</p>

            <ul>
                <li>Search your record at immigration and fraud prevention agencies</li>
                <li>Prevent illegal activities like money laundering, tax evasion and fraud</li>
                <li>Make reasonable enquiries to confirm your identity, and for Service Providers to ensure your
                    credentials are accurate.
                </li>
            </ul>

            <p>To exercise what’s known as our legitimate interests. This is when we use data for a reason which is in
                your and/or our interest, and which doesn't involve overriding your privacy rights. We use it to</p>

            <ul>
                <li>Market products and services generally in app</li>
                <li>Search your record at credit reference agencies when you sign up (this is a 'soft search' and
                    shouldn't impact your credit score).
                </li>
            </ul>

            <p>With your permission. We use it to:</p>
            <ul>
                <li>Market and communicate our products and services and those of affiliated partners where we think
                    these will be of interest to you by email. You can always unsubscribe from receiving these if you
                    want to, by email and via the app.
                </li>
            </ul>

            <h2>Who we share it with</h2>
            <p>We may share your personal information with:</p>
            <ul>
                <li>Anyone who works for us when they need it to do their job.</li>
                <li>Any organisation which supports any of our services you use, when they need it to offer those
                    services. That includes:
                    <ul>
                        <li>Card producers and networks</li>
                        <li>Credit reference agencies (for information on how they use data please read the Credit
                            Reference Agency Information Notice.)
                        </li>
                        <li>Analytical, Know Your Customer (KYC) and cyber security service providers.</li>
                        <li>Customer ‘interface’ providers (like the ones who manage our in-app chat service).</li>
                    </ul>
                </li>
                <li>Companies that do advertising for us (but we won’t share identifiable personal data with
                    third parties for their own direct marketing unless you give us permission, and you can opt
                    out any time).
                </li>
                <li>Anyone who you give us explicit permission to share it with</li>

                <li>We’ll also share it to comply with the law; to enforce our Terms and Conditions or other agreements;
                    or to protect the rights, property or safety of us, our customers or others.
                </li>
            </ul>

            <h2>How long we keep it</h2>
            <p>We keep your data as long as you’re using Heroes on Tap, and for 2 year after that to comply with the
                law. In some circumstances, like cases of anti-money laundering or fraud, we may keep data longer if we
                need to and/or the law says we have to.</p>

            <h2>Your rights</h2>
            <p>You have the right to:</p>
            <ul>
                <li>Access the personal data we hold about you, or to get a copy of it</li>
                <li>Make us correct inaccurate data</li>
                <li>Ask us to delete, 'block' or suppress your data, though for legal reasons we might not always be
                    able to do it.
                </li>
                <li>Object to us using your data for direct marketing and in certain circumstances ‘legitimate
                    interests’, research and statistical reasons.
                </li>
                <li>Withdraw any consent you've previously given us. To do so, please contact us through the app or by
                    emailing <a href="mailto:help@heroesontap.co.uk">help@heroesontap.co.uk</a>.
                </li>
            </ul>

            <h2>Where we store your data</h2>
            <p>We might transfer and store the data we collect from you somewhere outside the European Economic Area
                (‘EEA’). People who work for us or our suppliers outside the EEA might also process your data. We may
                share data with organisations and countries that:</p>
            <ul>
                <li>The European Commission say have adequate data protection</li>
                <li>We've agreed standard data protection clauses with.</li>
            </ul>

            <h2>How to make a compliant</h2>
            <p>If you have a complaint, please contact us through the app or by emailing <a href="mailto:help@heroesontap.co.uk">help@heroesontap.co.uk</a> and we'll do our best to fix the problem.</p>

            <h2>Changes to this policy</h2>
            <p>We'll post any changes we make to our privacy notice on this page and, if they're significant changes we'll let you know by email.</p>
        </div>

    </div>
</template>

<script>
    export default {
        name: "PrivacyPolicy"
    }
</script>

<style scoped>

</style>
