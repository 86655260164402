<template>
  <div class="signup">
    <div class="centering">
      <div class="grid">
        <div class="grid-xs-12 grid-s-6">
          <div class="signup-content">
            <h2 class="h1 title">{{ signup_title }}</h2>
            <p>Sign up now to make your life easier.</p>

            <!-- Google Play and App Store links -->
            <div class="app-links">
              <a href="https://play.google.com/store/apps/details?id=com.yourapp" target="_blank" class="btn-store">
                <img src="../assets/google-play-badge.png" alt="Get it on Google Play" />
              </a>
              <a href="https://apps.apple.com/us/app/yourapp/id1234567890" target="_blank" class="btn-store">
                <img src="../assets/appstore-badge.png" alt="Download on the App Store" />
              </a>
            </div>
          </div>
        </div>
        <div class="grid-xs-12 grid-s-6">
          <img src="../assets/super.svg">
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    export default {
        name: "Signup",

        data(){
            return {
                signup_title: this.$route.name == 'needAHero' ? 'Find your Hero' : 'Become a hero',
            }
        }
    }
</script>

<style lang="scss">

.app-links {
  display: flex;
  justify-content: center;
  margin: 20px 0;

  .btn-store {
    margin: 0 10px;
  }
}

</style>
