<template>
    <header class="header" role="banner">
        <div class="centering">
            <div class="header-logo">
                <router-link title="Home" :to="{name: 'home'}">Heroes on Tap</router-link>
            </div>

            <a class="hamburger" href="#" title="Menu">
                <span class="line-1"></span>
                <span class="line-2"></span>
                <span class="line-3"></span>
            </a>

            <nav class="main-nav" id="main-nav" role="navigation">
                <ul class="menu">
                    <li class="menu-item"><router-link title="Home" :to="{name: 'home'}">Home</router-link></li>
                    <li class="menu-item"><router-link title="Become a hero" :to="{name: 'becomeAHero' }">Become a hero</router-link></li>
                    <li class="menu-item"><router-link title="Find a hero" :to="{name: 'needAHero'}">Find a hero</router-link></li>
                </ul>
            </nav>
        </div>

    </header>
</template>

<script>
    export default {
        name: "Header",

        mounted() {
            this.initMenuBar();
        },

        methods: {
            initMenuBar(){
                $('.hamburger').on('click', function(event){
                    event.preventDefault();

                    $(this).toggleClass('is-active');
                    $('html, body').toggleClass('is-noscroll is-open-menu');
                    $('#main-nav').toggleClass('is-open-menu');
                });

                $('.menu-item').on('click', function(event){
                    $('.hamburger').removeClass('is-active');
                    $('html, body').removeClass('is-noscroll is-open-menu');
                    $('#main-nav').removeClass('is-open-menu');
                })
            }
        }
    }
</script>

<style scoped>

</style>
