<template>
    <div>
        <div class="grid">
            <div class="grid-xs-12 hero-section with-bg"   :style="{ backgroundImage: `url(${require('@/assets/handshake.jpg')})` }"
            >
                <div class="hero-content">
                    <div class="text-center">
                        <i class="icon lni-briefcase"></i>
                        <p class="intro-text"><strong>Become</strong> a</p>
                        <p class="big-text">Hero</p>
                    </div>
                </div>
            </div>
        </div>

        <signup></signup>

        <section class="section call-out-image">
            <div class="centering">
                <div class="grid">
                    <div data-aos="fade-down" class="grid-xs-12 grid-s-6">
                        <div class="callout-meta">
                            <h2 class="h1">Be found in seconds. </h2>
                            <p>
                                Become a hero today and jump to the client's rescue in your area. Build your business from the palm
                                of your hand and enhance your reputation with people in need of a hero.
                            </p>
                        </div>

                    </div>

                    <div data-aos="fade-up" class="grid-xs-12 grid-s-6">
                        <img class="callout-image" src="../assets/personal_text.svg" alt="White Iphone">
                    </div>
                </div>
            </div>
        </section>

        <section class="section grey-bg">
            <div class="centering">
                <h2 class="h1">Our Features.</h2>
                <div class="grid">
                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/diary.svg" alt="realtime">
                            <h2 class="icon-title">Manage your diary from your phone</h2>
                            <p>Book clients in once you have been sumoned with our built in app calendar. </p>
                        </div>
                    </div>

                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/customers.svg" alt="realtime">
                            <h2 class="icon-title">Build a customer base from your phone</h2>
                            <p>Allow yourself to come a hero and gain new clients all from your phone.. </p>
                        </div>
                    </div>

                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/calendar.svg" alt="realtime">
                            <h2 class="icon-title">Fill your quiet days with clients</h2>
                            <p>Start saving new clients wherever you have unwelcome gaps in the diary. </p>
                        </div>
                    </div>

                    <div class="grid-xs-12 grid-s-6">
                        <div data-aos="fade-in" class="icon-card">
                            <img src="../assets/features/connected.svg" alt="realtime">
                            <h2 class="icon-title">No payments until you're connected</h2>
                            <p>Why pay until it works for you? We don't activate your payments until you've made your first connection. </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    </div>
</template>

<script>
    import Signup from "./Signup";
    export default {
        name: "BecomeAHero",
        components: {Signup},
    }
</script>

<style scoped>

</style>
