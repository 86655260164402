<template>
  <div>
    <header-component></header-component>
    <transition name="page" mode="out-in">
      <router-view></router-view>
    </transition>
    <footer-component></footer-component>
  </div>
</template>

<script>
import HeaderComponent from "./components/HeaderComponent";
import FooterComponent from "./components/FooterComponent";
import AOS from 'aos';
import 'aos/dist/aos.css';
export default {
  name: "AppComponent",
  components: {FooterComponent, HeaderComponent},

  mounted() {
    AOS.init();

  },
}
</script>

<style lang="scss">
@import "./sass/app.scss";
.qc-cmp-button.qc-cmp-secondary-button {
  border-color: #eee !important;
  background-color: #eee !important;
}
</style>
